import React, { useEffect, useRef, useState, useCallback } from "react"
import styled, { css } from "styled-components"
import { color, font, ease, device } from "../../layout/global"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { useWindowSize } from "../../hooks/useWindowSize"

import Play from "../../images/Video/play.svg"
import Pause from "../../images/Video/pause.svg"
import SoundOn from "../../images/Video/sound_on.svg"
import SoundOff from "../../images/Video/sound_off.svg"
import MassaMadre from "../../images/MassaMadre.svg"
import Close from "../../images/Video/exit.svg"
import CTAScroll from "../CTA-Scroll"
import Banner from "../BannerPT2020"
import BannerFeira from "../BannerFeira"

import { useTranslation, I18nextContext } from "gatsby-plugin-react-i18next"

import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger)
  gsap.core.globals("ScrollTrigger", ScrollTrigger)
}

// COMPONENT
const Video = React.forwardRef(
  (
    { src, poster, srcp, className, id, imgMobile, fullString, toClick, isHomePage: homepage, noPreview, br, noAnimation, isEvent },
    ref,
    ...props
  ) => {
    const size = useWindowSize()
    const isLandscape = size.width >= size.height * 1.777777777777777778 ? true : false
    const activeLanguage = React.useContext(I18nextContext).language

    var banner2020 = true
    if (activeLanguage === 'mx' || activeLanguage === 'br') {
      banner2020 = false
    }



    // ANIMATIONS
    const refVideoContainer = useRef(null),
      refVideo = useRef(null),
      refCTA = useRef(null),
      refMM = useRef()

    useEffect(() => {
      if (typeof window !== "undefined" && window.matchMedia(device.tabletP).matches && !noAnimation) {
        gsap
          .timeline({
            paused: true,
            scrollTrigger: {
              id: "video",
              trigger: refVideoContainer.current,
              start: "top top",
              pin: true,
              pinSpacing: false,
            },
          })
          .fromTo(refVideoContainer.current, { opacity: 0 }, { opacity: 1, duration: 0.5, delay: 0.3 })
          .fromTo(refCTA.current.querySelector(`.scroll-line`), { height: "0px" }, { height: "30px", duration: 0.3 }, ">-0.75")
          .fromTo(refCTA.current.querySelector(`.scroll-text`), { opacity: 0 }, { opacity: 1, duration: 0.3 }, ">")
          .fromTo(refMM.current, { opacity: 0 }, { opacity: 1, duration: 0.3 }, "<")
      }
    }, [id])
    // ---


    const [hasControls, setHasControls] = useState(false)
    const [hasSetControls, setHasSetControls] = useState(true)
    const [isPlaying, setIsPlaying] = useState(true)
    const [isMuted, setIsMuted] = useState(true)
    const [mouseMoving, setMouseMoving] = useState(null)
    const refVideoControls = useRef(null)
    const refVideoShowControls = useRef(null)
    const refCurrentTime = useRef(null)
    const refDuration = useRef(null)
    const refVolumeControl = useRef(null)
    const fixRatio = useRef(null)

    /* Hide controls after 2.5secs */
    const timeout = useRef(null)
    const header = useRef(null)

    const initialMouseMove = () => {
      setMouseMoving(true)
        ; (() => {
          clearTimeout(timeout.current)
          timeout.current = setTimeout(function () {
            setMouseMoving(false)
          }, 2500)
        })()
    }
    const setMouseMove = () => {
      if (!hasSetControls) {
        setMouseMoving(true)
          ; (() => {
            clearTimeout(timeout.current)
            timeout.current = setTimeout(function () {
              setMouseMoving(false)
            }, 2500)
          })()
      }
    }
    /* Show media controls after pressing the "view video" button */
    const showControls = () => {
      initialMouseMove()
      setHasSetControls(false)
      refVideo.current.pause()
      refVideo.current.getElementsByTagName("source")[0].setAttribute("src", srcp)
      refVideo.current.load()
      refVideo.current.play()
      header.current = document.querySelector("#header")
      header.current.style.opacity = 0
      fixRatio.current = true
      setTimeout(function () {
        setHasControls(true)
        setIsMuted(false)
        refVideo && (refVideo.current.muted = false)
      }, 350)
    }
    /* Hide media controls after pressing the "close" button in media controls */
    const hideControls = () => {
      clearTimeout(timeout.current)
      header.current = document.querySelector("#header")
      header.current.style.opacity = 1
      fixRatio.current = false
      refVideo.current.pause()
      refVideo.current.getElementsByTagName("source")[0].setAttribute("src", src)
      refVideo.current.load()
      refVideo.current.play()
      setMouseMoving(false)
      setHasControls(false)
      setTimeout(function () {
        setHasSetControls(true)
        setIsMuted(true)
        setIsPlaying(true)
        refVideo && (refVideo.current.muted = true)
        refVideo && refVideo.current.play()
      }, 350)
    }
    /* Play video after clicking play button */
    const playVideo = useCallback(() => {
      refVideo && refVideo.current.play()
      setIsPlaying(true)
    }, [])
    /* Pauses video after clicking the pause button */
    const pauseVideo = useCallback(() => {
      refVideo && refVideo.current.pause()
      setIsPlaying(false)
    }, [])
    /* Mutes the video after pressing the volume button */
    const muteVideo = useCallback(() => {
      refVideo && (refVideo.current.muted = true)
      setIsMuted(true)
    }, [])
    /* Unmutes the video */
    const unmuteVideo = useCallback(() => {
      refVideo && (refVideo.current.muted = false)
      setIsMuted(false)
    }, [])
    /* Hovering the volume button displays the volume slider */
    const showVolumeSlider = useCallback(() => {
      document.querySelector("#vol-control").style.display = "block"
    }, [])
    /* Leaving the hover state hides the volume slider */
    const hideVolumeSlider = useCallback(() => {
      document.querySelector("#vol-control").style.display = "none"
    }, [])

    useEffect(() => {
      fixRatio.current = false

      // About page - Show video immediately playing
      if (noPreview) {
        initialMouseMove()
        setHasSetControls(false)
        refVideo.current.pause()
        refVideo.current.getElementsByTagName("source")[0].setAttribute("src", srcp)
        refVideo.current.load()
        refVideo.current.play()
        fixRatio.current = true
        setTimeout(function () {
          setHasControls(true)
        }, 350)
      }
    }, [noPreview, srcp])
    /* Creating all the event listeners for the custom media controls */
    useEffect(() => {
      if (typeof window !== "undefined" && window.matchMedia(device.tabletP).matches) {
        // Does the browser actually support the video element?
        const supportsVideo = !!document.createElement("video").canPlayType

        if (supportsVideo) {
          // Obtain handles to main elements
          const video = refVideo.current
          const currentTime = refCurrentTime.current
          const duration = refDuration.current
          const volumeControl = refVolumeControl.current
          // Hide the default controls
          video.controls = false
          // Obtain handles to buttons and other elements
          const mute = document.getElementById("mute")
          const progressContainer = document.getElementById("progress-container")
          const progress = document.getElementById("progress")
          const progressBar = document.getElementById("progress-bar")
          // If the browser doesn't support the progress element, set its state for some different styling
          var supportsProgress = document.createElement("progress").max !== undefined
          if (!supportsProgress) progress.setAttribute("data-state", "fake")
          // Only add the events if addEventListener is supported (IE8 and less don't support it, but that will use Flash anyway)
          if (document.addEventListener) {
            // Wait for the video's meta data to be loaded, then set the progress bar's max value to the duration of the video
            video.addEventListener("loadedmetadata", function () {
              progress.setAttribute("max", video.duration)
              duration.innerHTML =
                parseInt(video.duration / 60, 10) +
                ":" +
                parseInt(video.duration - Math.floor(video.duration / 60) * 60, 10).toLocaleString("en-US", {
                  minimumIntegerDigits: 2,
                  useGrouping: false,
                })
              document.onkeypress = function (e) {
                if (hasControls && (e || window.event).keyCode === 32) {
                  e.preventDefault()
                  if (video.paused) {
                    video.play()
                    setIsPlaying(true)
                  } else {
                    video.pause()
                    setIsPlaying(false)
                  }
                }
              }
            })
            // Changes the button state of certain button's so the correct visuals can be displayed with CSS
            var changeButtonState = function (type) {
              if (type === "mute") {
                mute.setAttribute("data-state", video.muted ? "unmute" : "mute")
              }
            }
            // Add events for all buttons
            mute.addEventListener("click", function (e) {
              video.muted = !video.muted
              changeButtonState("mute")
            })
            // Add events for the volume slider
            volumeControl.addEventListener("change", function () {
              video.volume = this.value / 100
              if (video.volume === 0) {
                muteVideo()
              } else {
                unmuteVideo()
              }
            })
            volumeControl.addEventListener("input", function () {
              video.volume = this.value / 100
              if (video.volume === 0) {
                muteVideo()
              } else {
                unmuteVideo()
              }
            })
            // As the video is playing, update the progress bar
            video.addEventListener("timeupdate", function () {
              // For mobile browsers, ensure that the progress element's max attribute is set
              if (!progress.getAttribute("max")) progress.setAttribute("max", video.duration)
              progress.value = video.currentTime
              let seconds = parseInt(video.currentTime, 10).toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
              })
              currentTime.innerHTML =
                parseInt(video.currentTime / 60, 10) +
                ":" +
                (seconds >= 60
                  ? parseInt(seconds - 60, 10).toLocaleString("en-US", {
                    minimumIntegerDigits: 2,
                    useGrouping: false,
                  })
                  : seconds)
              progressBar.style.width = Math.floor((video.currentTime / video.duration) * 100) + "%"
            })
            // React to the user clicking within the progress bar
            progressContainer.addEventListener("click", function (e) {
              //var pos = (e.pageX  - this.offsetLeft) / this.offsetWidth; // Also need to take the parent into account here as .controls now has position:relative
              var pos = (e.pageX - (this.offsetLeft + this.offsetParent.offsetLeft)) / this.offsetWidth
              video.currentTime = pos * video.duration
            })
          }
        }
      }
    }, [unmuteVideo, muteVideo, hasControls])

    const breakpoints = useBreakpoint()

    return (
      <Wrapper
        ref={ref}
        id={id}
        className={className}
        hasControls={hasControls}
        hasSetControls={hasSetControls}
        mouseMoving={mouseMoving}
        imgMobile={imgMobile}
        isHomePage={fixRatio.current}
        isLandscape={isLandscape}
        noAnimation={noAnimation}
      >
        {breakpoints.tl ? (
          <>
            <div className="image-mobile">
              <img
                src={imgMobile}
                alt="feature"
                style={{
                  objectFit: "contain",
                  objectPosition: "center center",
                }}
              />
            </div>
            {banner2020 && homepage && <Banner hasSetControls={hasSetControls} mobile={true} className="banner-pt2020" />}
            {(isEvent && homepage) && <BannerFeira hasSetControls={hasSetControls} className="banner-pt2020"  mobile={true} image={isEvent}/>}
          </>
        ) : (
          <figure
            id="videoContainer"
            data-fullscreen="false"
            ref={refVideoContainer}
            onMouseMove={setMouseMove}
            role="presentation"
          >
            <video id="video" poster={poster} autoPlay playsInline muted loop ref={refVideo}>
              <source src={src} type="video/mp4"></source>
            </video>
            <div id="video-controls" className="controls" ref={refVideoControls}>
              <div id="progress-container" className="progress">
                <progress id="progress" value="0" min="0">
                  <span id="progress-bar"></span>
                </progress>
              </div>
              <button id="video-show-controls" ref={refVideoShowControls}>
                <Play className="icon" onMouseUp={showControls} />
                <p onMouseUp={showControls} role="presentation">
                  {fullString}
                </p>
              </button>
              <button id="playpause" type="button">
                {isPlaying ? <Pause className="icon" onClick={pauseVideo} /> : <Play className="icon" onClick={playVideo} />}
              </button>
              <div id="volume" role="button" tabIndex={0} onMouseEnter={showVolumeSlider} onMouseLeave={hideVolumeSlider}>
                <button id="mute" type="button" data-state="mute">
                  {isMuted ? (
                    <SoundOff className="icon active" onClick={unmuteVideo} />
                  ) : (
                    <SoundOn className="icon" onClick={muteVideo} />
                  )}
                </button>
                <input id="vol-control" type="range" min="0" max="100" step="1" ref={refVolumeControl} />
              </div>
              <div id="duration">
                <span ref={refCurrentTime}></span>
                <span>&nbsp;/&nbsp;</span>
                <span ref={refDuration}></span>
              </div>
              <button id="close" type="button" data-state="close" onClick={hideControls} aria-label="Close">
                <Close className="icon" />
              </button>
            </div>
            <CTAScroll className="cta" text="scroll" toClick={toClick} ref={refCTA} />
            {banner2020 && homepage && <Banner hasSetControls={hasSetControls} className="banner-pt2020" />}
            {(isEvent && homepage) && <BannerFeira hasSetControls={hasSetControls} className="banner-pt2020" image={isEvent}/>}
            {br && (
              <a
                href="https://massamadreblog.com.br/"
                target="_blank"
                rel="noopener noreferrer"
                className="massa-madre"
                ref={refMM}
              >
                <MassaMadre />
              </a>
            )}
          </figure>
        )}
      </Wrapper>
    )
  }
)

export default Video

const Wrapper = styled.div`
  position: relative;
  max-height: 100vh;
  max-width: 177.78vh;
  background-color: #000000;
  height: 100vh;
  width: 100vw;

  .image-mobile {
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .gatsby-image-wrapper {
      height: 100%;
    }

    img {
      object-fit: cover !important;
    }
  }

  ${props =>
    props.isHomePage
      ? css`
          #videoContainer {
            background-color: black;
            display: flex;
            justify-content: center;
            ${props =>
          props.isLandscape
            ? css`
                    height: 100vh !important;
                  `
            : css`
                    width: 100vw !important;
                  `}
          }

          video {
            pointer-events: none;
            background-color: black;
            ${props =>
          props.isLandscape
            ? css`
                    height: 100%;
                  `
            : css`
                    width: 100%;
                  `}
          }
        `
      : css`
          #videoContainer {
            position: ${props => (props.noAnimation ? "absolute !important" : "absolute")};
            width: 100%;
            height: 100%;
          }

          video {
            pointer-events: none;
            background-color: black;
            object-fit: ${props => (props.noAnimation ? "cover" : "contain")};
            width: ${props => (props.noAnimation ? "100% !important" : "unset")};
            ${props =>
          props.isLandscape
            ? css`
                    height: 100%;
                  `
            : css`
                    width: 100%;
                  `}

            @media ${device.mobileP} {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              min-width: 100%;
              min-height: 100%;
              width: auto;
              height: auto;
            }
          }
        `}

  .icon {
    width: 100%;
    height: 100%;
    fill: ${color.greyDarker};
    &:hover {
      fill: ${color.red};
    }
  }
  .icon.active {
    fill: ${color.red};
  }
  .icon-special {
    width: 16px;
    height: 16px;
    fill: ${color.white};
  }
  #volume {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-areas: "mute line";
    height: 16px;
    margin-right: 10px;
  }
  #mute {
    grid-area: mute;
    margin-right: 0px;
  }
  /* Volume button */
  #vol-control {
    display: none;
    width: 60px;
    margin-left: 5px;
    grid-area: line;
  }
  #vol-control::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: 1px solid transparent;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: ${color.white};
    cursor: pointer;
    transform: translateY(-40%);
  }
  #vol-control::-moz-range-thumb {
    border: 1px solid transparent;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: ${color.red};
    cursor: pointer;
  }
  #vol-control::-ms-thumb {
    border: 1px solid transparent;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: ${color.red};
    cursor: pointer;
  }
  /* Volume slider */
  #vol-control::-webkit-slider-runnable-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    background: ${color.red};
  }
  #vol-control:focus::-webkit-slider-runnable-track {
    background: ${color.red};
  }
  #vol-control::-moz-range-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    background: ${color.red};
  }
  #vol-control::-ms-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    background: transparent;
    color: transparent;
  }
  #vol-control::-ms-fill-lower {
    background: ${color.red};
  }
  #vol-control:focus::-ms-fill-lower {
    background: ${color.red};
  }
  #vol-control::-ms-fill-upper {
    background: ${color.red};
  }
  #vol-control:focus::-ms-fill-upper {
    background: ${color.red};
  }

  /* Fix for the controls to stay in the right place */
  .progress {
    grid-area: progress;
  }
  #playpause {
    grid-area: playpause;
  }
  #mute {
    grid-area: mute;
  }
  #close {
    grid-area: close;
  }

  .controls {
    position: absolute;
    bottom: 0;
    overflow: hidden;
    background: transparent;
    width: 90%;
    margin: 0 5% 2.5%;
    display: grid;
    grid-template-rows: auto 1fr;
    grid-template-columns: repeat(4, auto) 1fr;
    grid-row-gap: 7.5px;
    grid-template-areas:
      "progress progress progress progress progress"
      "playpause mute . . close";
  }
  #video-controls {
    & * {
      pointer-events: ${props => (props.hasControls && props.mouseMoving ? "all" : "none")};
      opacity: ${props => (props.hasControls && props.mouseMoving ? "1" : "0")};
      display: grid;
      transition: all 0.3s ${ease.out};
    }
    & #video-show-controls,
    & #video-show-controls * {
      opacity: ${props => (props.hasSetControls ? "1" : "0")};
      pointer-events: ${props => (props.hasSetControls ? "all" : "none")};
      transition: all 0.3s ${ease.out};
    }
  }
  #video-show-controls {
    position: absolute;
    bottom: 0;
    display: flex;
    outline: 0;
    width: auto;

    & .icon {
      width: 16px;
    }
    & p {
      margin-left: 5px;
      color: ${color.greyOpacity};
      ${font.robotoMedium};
      font-size: 1.1rem;
      text-transform: uppercase;
      line-height: normal;
      align-self: center;
    }
    &:hover p {
      color: ${color.red};
    }
    &:hover .icon {
      fill: ${color.red};
    }
  }
  .controls progress {
    display: block;
    width: 100%;
    height: 2px;
    margin-top: 2px;
    margin-top: 0.125rem;
    border: none;
    overflow: hidden;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    color: ${color.red}; /* Internet Explorer uses this value as the progress bar's value colour */
  }
  .controls progress span {
    width: 0%;
    height: 100%;
    display: inline-block;
    background-color: ${color.red};
  }
  .controls progress::-moz-progress-bar {
    background-color: ${color.red};
  }
  /* Chrome requires its own rule for this, otherwise it ignores it */
  .controls progress::-webkit-progress-value {
    background-color: ${color.red};
  }
  .controls > * {
    height: 100%;
  }
  .controls button {
    width: 16px;
    height: 16px;
    border: 0;
    padding: 0;
    background: none;
    outline: 0;
    margin-right: 10px;
    cursor: pointer;
  }
  .progress {
    cursor: pointer;
    padding-top: 7.5px;
    padding-bottom: 7.5px;
  }
  #duration {
    color: ${color.greyDarker};
    ${font.robotoMedium};
    font-size: 1.1rem;
    line-height: 1;
    letter-spacing: 0.8px;
    height: 16px;
    display: flex;
    align-items: center;
    pointer-events: none;
  }
  #close {
    justify-self: right;
    margin-right: 0;
  }
  .cta {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    margin-bottom: 2.5%;
    opacity: ${props => (props.hasSetControls ? "1" : "0")};

    & .scroll-container {
      pointer-events: ${props => (props.hasSetControls ? "all" : "none")};
    }
  }

  .dark-background {
    padding-bottom: 10rem;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
  }

  .banner-pt2020 {
    transition: all 0.3s ease-out;
  }

  .massa-madre {
    position: absolute;
    right: 0;
    bottom: 0;
    margin-right: 5%;
    margin-bottom: 2.5%;
    opacity: ${props => (props.hasSetControls ? "1" : "0")};
    pointer-events: ${props => (props.hasSetControls ? "all" : "none")};

    & svg {
      fill: ${color.red};
      width: 150px;
      transition: all 0.3s ${ease.out};
      opacity: ${props => (props.hasSetControls ? "1" : "0")};
      pointer-events: ${props => (props.hasSetControls ? "all" : "none")};

      &:hover {
        fill: ${color.grey};
      }
    }
  }
`