import React, { useState } from "react"
import styled, { css } from "styled-components"
import { useScrollPosition } from "../../hooks/useScrollPosition"

import { ease } from "../../layout/global"

const BannerFeira = props => {
  const [isVisible, setIsVisible] = useState(true)

  useScrollPosition(({ currPos }) => {
    let position = Math.abs(currPos.y)
    position > 0 ? setIsVisible(false) : setIsVisible(true)
  }, [])

  return (
    <StyledBanner
      className={props.className}
      isVisible={isVisible}
      hasSetControls={props.hasSetControls}
      mobile={props.mobile}
    >
    
        <img 
            src={props.image} 
            alt='Event' 
            style={{
                objectFit:'contain', 
                objectPosition:'center center'
            }}
        />
    
    </StyledBanner>
  )
}

export default BannerFeira

const StyledBanner = styled.div`
  position: absolute;
  ${props =>
    props.mobile
      ? css`
          left: 20px;
          bottom: 15%;
          top: auto;
          right: auto;
        `
      : css`
        left: 3%;
        top: 15%;
        bottom: auto;
        right: auto;
        `}

  line-height: 0;

  opacity: ${props =>
    props.isVisible && (props.hasSetControls || props.mobile) ? "1" : "0"};
  pointer-events: ${props =>
    props.isVisible && (props.hasSetControls || props.mobile) ? "all" : "none"};
  transition: all 0.3s ${ease.out};

  .hidden {
    opacity: 0;
    pointer-events: none;
  }

  img{
    width: auto;
    max-height: ${props =>
      props.mobile
      ?
      '12vh'
      :
      '15vh'
  }
`
