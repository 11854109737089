import React, { useState } from "react"
import styled, { css } from "styled-components"
import { useScrollPosition } from "../../hooks/useScrollPosition"

import { font, color, ease } from "../../layout/global"

const Banner = props => {
  const [isVisible, setIsVisible] = useState(true)

  useScrollPosition(({ currPos }) => {
    let position = Math.abs(currPos.y)
    position > 0 ? setIsVisible(false) : setIsVisible(true)
  }, [])

  const showDiv = a => {
    let div = document.querySelector(a)
    let main = document.querySelector(".banner-pt2020")
    if (div.classList.contains("hidden") === true) {
      div.classList.remove("hidden")
      props.mobile && main.classList.add("dark-background")
    } else {
      div.classList.add("hidden")
      props.mobile && main.classList.remove("dark-background")
    }
  }

  const hoverDiv = (a, b) => {
    let div = document.querySelector(b)
    if (a === "enter") {
      div.classList.remove("hidden")
    } else if (a === "leave") {
      div.classList.add("hidden")
    }
  }

  return (
    <StyledBanner
      className={props.className}
      isVisible={isVisible}
      hasSetControls={props.hasSetControls}
      mobile={props.mobile}
    >
      <button
        onClick={() => showDiv(".pt2020-links.top")}
        onMouseEnter={() => hoverDiv("enter", ".pt2020-links.top")}
        onMouseLeave={() => hoverDiv("leave", ".pt2020-links.top")}
        className="pt2020-container"
      >
        <img 
            src={'/images/pt2020_t.png'} 
            alt='pt2020' 
            style={{
                objectFit:'contain', 
                objectPosition:'center center'
            }}
        />
      </button>
      <div className="top pt2020-links hidden">
        <a
          href="/download/ficha_projeto_poci-02-0752-feder-034274.pdf"
          rel="noreferrer noopener"
          target="_blank"
        >
          <span>POCI-02-0752-FEDER-034274</span>
        </a>
        <a
          href="/download/ficha_projeto_poci-02-0853-feder-000597.pdf"
          rel="noreferrer noopener"
          target="_blank"
        >
          <span>POCI-02-0853-FEDER-000597</span>
        </a>
        <a
          href="/download/ficha_projeto_poci-02-0853-feder-041263.pdf"
          rel="noreferrer noopener"
          target="_blank"
        >
          <span>POCI-02-0853-FEDER-041263</span>
        </a>
      </div>
    </StyledBanner>
  )
}

export default Banner

const StyledBanner = styled.div`
  position: absolute;
  ${props =>
    props.mobile
      ? css`
          left: 50%;
          top: 0;
          margin-top: 75px;
          transform: translateX(-50%);
          background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0.8),
            rgba(0, 0, 0, 0)
          );
        `
      : css`
          right: 0;
          top: 30%;
          margin-right: 3%;
        `}

  line-height: 0;

  opacity: ${props =>
    props.isVisible && (props.hasSetControls || props.mobile) ? "1" : "0"};
  pointer-events: ${props =>
    props.isVisible && (props.hasSetControls || props.mobile) ? "all" : "none"};
  transition: all 0.3s ${ease.out};

  .pt2020-container {
    width: 290px;
    margin: 0;
    padding-right: 1em;
    cursor: pointer;
    border: none;
    outline: none;
    background: none;

    .gatsby-image-wrapper {
      width: 100%;
      transition: all 0.3s ${ease.out};
      &:hover {
        opacity: 0.8;
      }
    }
  }

  .pt2020-links {
    padding-top: 15px;
    ${font.robotoMedium};
    font-size: 1.2rem;
    transition: all 0.3s ${ease.out};
    justify-self: start;
    display: flex;
    flex-direction: column;
    line-height: normal;

    & a {
      text-align: ${props => (props.mobile ? "center" : "right")};
      padding-bottom: 2px;
      color: ${color.white} !important;
    }

    &:hover {
      pointer-events: all;
      opacity: 1;
    }
  }

  .hidden {
    opacity: 0;
    pointer-events: none;
  }
`
